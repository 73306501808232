import React, { useState, useEffect, useCallback } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import MainService from './services/MainService'
import history from './history'
import LayoutMain from './layouts/LayoutMain'

import Home from './views/Home'
import Response from './views/Response'
import Faqs from './views/Faqs'
import Feedback from './views/Feedback'
import Success from './views/Success'
import Fonasa from './views/Fonasa'
import Isapre from './views/Isapre'
import Maintenance from './views/Maintenance'

import ReactGA from 'react-ga4'
const TRACKING_ID = 'G-R855JD1ZMD'
ReactGA.initialize(TRACKING_ID, {
    // cookieDomain: 'auto',
    // debug: true,
})

const App = () => {
    const [loading, setLoading] = useState(true)
    const [texts, setTexts] = useState({})

    const getTexts = useCallback(async () => {
        try {
            const data = await MainService.getTexts()
            setTexts(data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        try {
            getTexts()
            ReactGA.pageview('home')
        } catch (error) {
            setLoading(false)
        }
    }, [getTexts])

    return (
        <Router history={history}>
            <LayoutMain loading={loading} setLoading={setLoading} texts={texts}>
                <Routes>
                    {/* <Route exact path="/" element={<Maintenance />}></Route> */}
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/response/" element={<Response />}></Route>
                    <Route path="/faqs" element={<Faqs />}></Route>
                    <Route path="/feedback" element={<Feedback />}></Route>
                    <Route path="/success" element={<Success />}></Route>
                    <Route path="/fonasa" element={<Fonasa />}></Route>
                    <Route path="/isapre" element={<Isapre />}></Route>
                    <Route path="*" element={<Home />}></Route>
                </Routes>
            </LayoutMain>
        </Router>
    )
}

export default App
