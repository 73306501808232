import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import FormFeedback from './FormFeedback'
import { useLocation } from 'react-router-dom'
import BackHome from '../components/utils/BackHome'
import './style.css'

const Response = () => {
    const location = useLocation()
    const params = location.state

    useEffect(() => {
        ReactGA.pageview('feedback')
    }, [])

    return (
        <section className="feedback">
            <div className="container-s">
                <div className="content">
                    <FormFeedback
                        data={{
                            medicine: params.tag,
                            system: params.system,
                        }}
                    />
                </div>
                <BackHome />
            </div>
        </section>
    )
}

export default Response
