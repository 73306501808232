import React, { useEffect, useState, useCallback } from 'react'
import MainService from '../services/MainService'
import Loader from '../components/utils/Loader'
import BackHome from '../components/utils/BackHome'
import Convenio from '../components/utils/Convenio'
import { Checkbox, Modal, Button, Row, Col } from 'antd'
import { useSearchParams, useNavigate } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import ReactGA from 'react-ga4'
import './style.css'

const Response = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [coverage, setCoverage] = useState([])
    const [searchParams] = useSearchParams()
    const [params] = useState({
        medicine: searchParams.get('medicine'),
        system: searchParams.get('system'),
        tag: searchParams.get('tag'),
    })
    const [options, setOptions] = useState([])
    const [isDisableOptions, setIsDisableOptions] = useState(false)
    const [isDisableNothing, setIsDisableNothing] = useState(false)

    const handleCancel = () => {
        setIsModalVisible(false)
    }
    // end modal

    const getCoverage = useCallback(async () => {
        try {
            const data = await MainService.getCoverage(params)
            setCoverage(data.consult)
            setLoading(false)

            ReactGA.event({
                category: 'user',
                action: 'search medicine',
                label: params.tag,
            })
        } catch (error) {
            setLoading(false)
        }
    }, [params])

    useEffect(() => {
        try {
            getCoverage()
            ReactGA.pageview('response')
        } catch (error) {
            setLoading(false)
        }
    }, [getCoverage])

    const handleCheck = (item) => {
        console.log(item)
        const isExist = options.indexOf(item)
        console.log('existe', isExist)
        let auxArray = options
        if (isExist !== -1) {
            auxArray.splice(isExist, 1)
        } else {
            auxArray.push(item)
        }
        setOptions([...auxArray])
        if (auxArray.length > 0) {
            setIsDisableNothing(true)
        } else {
            setIsDisableNothing(false)
        }
        console.log(auxArray)
    }

    const handleNot = (e) => {
        if (isDisableOptions === true) {
            setIsDisableOptions(false)
        } else {
            setIsDisableOptions(true)
        }
    }

    const handleNavigate = async (convenio) => {
        navigate(`/${convenio}`, {
            state: params,
        })
    }

    const handleOk = async (convenio) => {
        console.log(options)
        await MainService.postCoverage({ use: 'si', reasons: options })
        navigate('/success')
    }

    const handleFeedback = async () => {
        // ReactGA.event({
        //     category: 'user',
        //     action: 'useful no',
        // })
        await MainService.postCoverage({ use: 'no', reasons: options })
        navigate('/feedback', {
            state: params,
        })
    }

    if (loading) {
        return <Loader center padding={'5rem 1rem'} />
    }

    return (
        <>
            <Modal visible={isModalVisible} onCancel={handleCancel} footer={null} width={450}>
                <h1>hola</h1>
                {/* <FormFeedback
                    setIsModalVisible={setIsModalVisible}
                    data={{ medicine: params.tag, system: params.system }}
                /> */}
            </Modal>
            <section className="response">
                <div className="container-s">
                    <div className="wrapper">
                        <Row>
                            <Col md={18} xs={24}>
                                <div className="label">Medicamento seleccionado</div>
                                <h2>
                                    <span className="capitalize">{coverage?.tag}</span>
                                    {coverage.tag?.toLowerCase() !== coverage.name?.toLowerCase() && (
                                        <>
                                            <span> - </span>
                                            <span className="capitalize">({coverage.name})</span>
                                        </>
                                    )}
                                </h2>
                            </Col>
                            <Col md={6} xs={24}>
                                <div className="label">Previsión de salud</div>
                                <h2 className="capitalize">{coverage.system}</h2>
                            </Col>
                        </Row>
                        <div className="body">
                            <div className="headline">
                                <p>{ReactHtmlParser(coverage.headline)}</p>
                            </div>
                            <div className="options">
                                {coverage.problems
                                    ? Object.entries(coverage.problems).map((problem, indexProblem) => {
                                          problem = problem[1]
                                          return (
                                              problem.coverage.length > 0 && (
                                                  <div className="item" key={indexProblem}>
                                                      <ul className={isDisableOptions === true ? 'own-response-block' : 'own-response'}>
                                                          {problem.coverage.map((item, indexCoverage) => (
                                                              <li>
                                                                  <Row key={indexCoverage} className={isDisableOptions === true ? 'own-response-block' : ''}>
                                                                      <Col span={22}>{ReactHtmlParser(item.description)}</Col>
                                                                      <Col span={2} className="icon">
                                                                          <Checkbox
                                                                              disabled={isDisableOptions}
                                                                              onClick={() => handleCheck(item.string)}
                                                                              style={{ margin: '0px 5px' }}
                                                                          />
                                                                      </Col>
                                                                  </Row>
                                                              </li>
                                                          ))}
                                                      </ul>
                                                      <ul
                                                          className={isDisableNothing === true ? 'own-response-block' : 'own-response'}
                                                          style={{ marginTop: '-17px' }}
                                                      >
                                                          <li>
                                                              <Row className={isDisableNothing === true ? 'own-response-block' : ''}>
                                                                  <Col span={22}>Ninguna de las anteriores.</Col>
                                                                  <Col span={2} className="icon">
                                                                      <Checkbox
                                                                          disabled={isDisableNothing}
                                                                          onClick={(e) => handleNot()}
                                                                          style={{ margin: '0px 5px' }}
                                                                      />
                                                                  </Col>
                                                              </Row>
                                                          </li>
                                                      </ul>
                                                      <Row>{isDisableNothing === true && ReactHtmlParser(problem.instructions)}</Row>
                                                  </div>
                                              )
                                          )
                                      })
                                    : ''}
                            </div>
                        </div>

                        <div className="footer">
                            {isDisableOptions === true && <Convenio onClick={() => handleNavigate(params.system)} />}

                            {isDisableNothing === true && (
                                <>
                                    <div className="question">
                                        <h3>¿Has podido hacer uso de este derecho?</h3>
                                        <div>
                                            <Button type="primary" onClick={() => handleOk(params.system)}>
                                                Si
                                            </Button>
                                            <Button type="primary" onClick={() => handleFeedback()}>
                                                No
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <BackHome />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Response
